import axios from '@/axios';

class ClienteServices {
  async fetchClientes() {
      try {
          const response = await axios.get('/clientes');
          return response.data.map(cliente => ({
              ...cliente,
              display_name: cliente.razao_social
          }));
      } catch (error) {
          console.error('Error fetching clientes:', error);
      }
  }
}

export default new ClienteServices();