<template>
    <div class="container-fluid py-4">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header pb-0">
                        <div class="d-flex align-items-center">
                            <p class="mb-0">Lista de Categorias</p>
                            <button class="btn btn-primary btn-sm ms-auto" @click="showAddCategoria">Adicionar
                                Categoria</button>
                        </div>
                    </div>
                    <div style="min-height:60vh" class="card-body">
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Nome</th>
                                        <th scope="col">Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(category, index) in categories" :key="category.id">
                                        <th scope="row">{{ index + 1 }}</th>
                                        <td>{{ category.nome }}</td>
                                        <td>
                                            <button class="btn btn-sm btn-warning m-lg-2"
                                                @click="showEditCategoryModal(category)">Editar</button>
                                            <button class="btn btn-sm btn-danger m-lg-2"
                                                @click="deleteCategory(category.id)">Excluir</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import axios from '@/axios';
import Swal from 'sweetalert2';

const categories = ref([]);

const fetchCategories = async () => {
    try {
        const response = await axios.get('/categorias');
        categories.value = response.data;
    } catch (error) {
        console.error('Erro ao buscar categorias:', error);
    }
}

onMounted(async () => {
    await fetchCategories();
});


const showAddCategoria = async () => {

    Swal.fire({
        title: 'Adicionar Categoria',
        input: 'text',
        inputLabel: 'Nome da Categoria',
        inputPlaceholder: 'Digite o nome da categoria',
        showCancelButton: true,
        confirmButtonText: 'Adicionar',
        cancelButtonText: 'Cancelar',
        preConfirm: (nome) => {
            if (!nome) {
                Swal.showValidationMessage('O nome da categoria é obrigatório');
            }
            return nome;
        }
    }).then(async (result) => {
        if (result.isConfirmed) {
            try {
                const response = await axios.post('/categorias', { nome: result.value });
                categories.value.push(response.data);
                Swal.fire('Sucesso', 'Categoria adicionada com sucesso!', 'success');

            } catch (error) {
                Swal.fire('Erro', 'Erro ao adicionar categoria.', 'error');
                console.log(error);
            }
        }
    });

};

async function deleteCategory(categoryId) {
    try {
        await axios.delete(`/categorias/${categoryId}`);
        categories.value = categories.value.filter(category => category.id !== categoryId);
        Swal.fire('Sucesso', 'Categoria excluída com sucesso!', 'success');
    } catch (error) {
        Swal.fire('Erro', 'Erro ao excluir categoria.', 'error');
    }
}

async function showEditCategoryModal(category) {
    Swal.fire({
        title: 'Editar Categoria',
        input: 'text',
        inputLabel: 'Nome da Categoria',
        inputValue: category.nome,
        showCancelButton: true,
        confirmButtonText: 'Salvar',
        cancelButtonText: 'Cancelar',
        preConfirm: (nome) => {
            if (!nome) {
                Swal.showValidationMessage('O nome da categoria é obrigatório');
            }
            return nome;
        }
    }).then(async (result) => {
        if (result.isConfirmed) {
            try {
                const response = await axios.put(`/categorias/${category.id}`, { nome: result.value });
                category.nome = response.data.nome;
                Swal.fire('Sucesso', 'Categoria editada com sucesso!', 'success');
            } catch (error) {
                Swal.fire('Erro', 'Erro ao editar categoria.', 'error');
            }
        }
    });
}
</script>

<style scoped>
.main-content {
    padding-top: 4rem;
}
</style>
