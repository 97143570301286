<template>
  <div class="main-content position-relative max-height-vh-100 h-100">
    <div class="container-fluid py-4">
      <!-- Estatísticas -->
      <div class="row">
        <div class="col-lg-3 col-md-6 col-12">
          <div class="mb-4 card">
            <div class="p-3 card-body">
              <div class="d-flex flex-row-reverse justify-content-between">
                <div>
                  <div class="text-center icon icon-shape bg-gradient-primary border-radius-2xl">
                    <i class="text-lg opacity-10 fa fa-phone" aria-hidden="true"></i>
                  </div>
                </div>
                <div>
                  <div class="numbers">
                    <p class="mb-0 text-sm text-uppercase font-weight-bold">ATENDIMENTOS</p>
                    <h5 class="font-weight-bolder">{{ totalAtendimentos }}</h5>
                    <span class="text-sm text-success">Atendimentos realizados</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-12">
          <div class="mb-4 card">
            <div class="p-3 card-body">
              <div class="d-flex flex-row-reverse justify-content-between">
                <div>
                  <div class="text-center icon icon-shape bg-gradient-danger border-radius-2xl">
                    <i class="text-lg opacity-10 ni ni-world" aria-hidden="true"></i>
                  </div>
                </div>
                <div>
                  <div class="numbers">
                    <p class="mb-0 text-sm text-uppercase font-weight-bold">ATENDIMENTOS HOJE</p>
                    <h5 class="font-weight-bolder">{{ atendimentosHoje }}</h5>
                    <span class="text-sm text-success">Hoje {{ currentDate }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-12">
          <div class="mb-4 card">
            <div class="p-3 card-body">
              <div class="d-flex flex-row-reverse justify-content-between">
                <div>
                  <div class="text-center icon icon-shape bg-gradient-success border-radius-2xl">
                    <i class="text-lg opacity-10 ni ni-paper-diploma" aria-hidden="true"></i>
                  </div>
                </div>
                <div>
                  <div class="numbers">
                    <p class="mb-0 text-sm text-uppercase font-weight-bold">EM ABERTO</p>
                    <h5 class="font-weight-bolder">{{ atendimentosEmAberto }}</h5>
                    Em atendimento
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-12">
          <div class="mb-4 card">
            <div class="p-3 card-body">
              <div class="d-flex flex-row-reverse justify-content-between">
                <div>
                  <div class="text-center icon icon-shape bg-gradient-warning border-radius-2xl">
                    <i class="text-lg opacity-10 fa fa-clock" aria-hidden="true"></i>
                  </div>
                </div>
                <div>
                  <div class="numbers">
                    <p class="mb-0 text-sm text-uppercase font-weight-bold">AGENDADOS</p>
                    <h5 class="font-weight-bolder">{{ atendimentosAgendados }}</h5>
                    Chamados agendados
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Gráficos -->
      <div class="row">
        <div class="col-md-6">
          <div class="card">
            <div class="card-header pb-0">
              <p class="mb-0"></p>
            </div>
            <div class="card-body">
              <canvas id="clientChart"></canvas>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card">
            <div class="card-header pb-0">
              <p class="mb-0"></p>
            </div>
            <div class="card-body">
              <canvas id="userChart"></canvas>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 mb-3">
          <div class="card">
            <div class="card-header pb-0">
              <p class="mb-0"></p>
            </div>
            <div class="card-body">
              <canvas id="productChart"></canvas>
            </div>
          </div>
        </div>
        <div class="col-md-6 mg-3">
          <div class="card">
            <div class="card-header pb-0">
              <p class="mb-0"></p>
            </div>
            <div class="card-body">
              <canvas id="categoryChart"></canvas>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import axios from '../axios';
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

// Registre os componentes necessários do Chart.js
Chart.register(...registerables, ChartDataLabels);

export default {
  setup() {
    const totalAtendimentos = ref(0);
    const atendimentosHoje = ref(0);
    const atendimentosEmAberto = ref(0);
    const atendimentosAgendados = ref(0);
    const produtos = ref([]);
    const categorias = ref([]);
    const clientes = ref([]);
    const usuarios = ref([]);

    const fetchData = async () => {
      try {
        const response = await axios.get('/dashboard');
        totalAtendimentos.value = response.data.totalAtendimentos;
        atendimentosHoje.value = response.data.atendimentosHoje;
        atendimentosEmAberto.value = response.data.atendimentosEmAberto;
        atendimentosAgendados.value = response.data.atendimentosAgendados;

        clientes.value = response.data.clientes;
        usuarios.value = response.data.usuarios;
        produtos.value = response.data.produtos;
        categorias.value = response.data.categorias;

        // Gráficos
        createChart('clientChart', 'Distribuição por Cliente', processChartData(clientes.value));
        createChart('userChart', 'Distribuição por Usuário', processChartData(usuarios.value));
        createChart('productChart', 'Distribuição por Produto', processChartData(produtos.value));
        createChart('categoryChart', 'Distribuição por Categoria', processChartData(categorias.value));
      } catch (error) {
        console.error('Erro ao buscar atendimentos:', error);
      }
    };

    const processChartData = (data) => {
      const labels = Object.keys(data);
      const values = Object.values(data);

      return {
        labels: labels,
        datasets: [
          {
            label: 'Quantidade',
            data: values,
            backgroundColor: [
              'rgba(255, 99, 132, 0.6)',  // Rosa Claro
              'rgba(54, 162, 235, 0.6)',  // Azul Claro
              'rgba(255, 206, 86, 0.6)',  // Amarelo Claro
              'rgba(75, 192, 192, 0.6)',  // Verde Água
              'rgba(153, 102, 255, 0.6)', // Roxo Claro
              'rgba(255, 159, 64, 0.6)',  // Laranja Claro
              'rgba(255, 99, 71, 0.6)',   // Vermelho Suave
              'rgba(201, 203, 207, 0.6)', // Cinza Claro
              'rgba(255, 193, 7, 0.6)',   // Amarelo Ouro
              'rgba(0, 204, 255, 0.6)',   // Azul Turquesa
              'rgba(123, 239, 178, 0.6)'  // Verde Menta
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',    // Rosa Claro
              'rgba(54, 162, 235, 1)',    // Azul Claro
              'rgba(255, 206, 86, 1)',    // Amarelo Claro
              'rgba(75, 192, 192, 1)',    // Verde Água
              'rgba(153, 102, 255, 1)',   // Roxo Claro
              'rgba(255, 159, 64, 1)',    // Laranja Claro
              'rgba(255, 99, 71, 1)',     // Vermelho Suave
              'rgba(201, 203, 207, 1)',   // Cinza Claro
              'rgba(255, 193, 7, 1)',     // Amarelo Ouro
              'rgba(0, 204, 255, 1)',     // Azul Turquesa
              'rgb(102, 221, 137)'        // Verde Menta Escuro
            ],
            borderWidth: 1,
          },
        ],
      };
    };

    const createChart = (chartId, title, data) => {
      const ctx = document.getElementById(chartId).getContext('2d');
      new Chart(ctx, {
        type: 'doughnut',
        data: data,
        options: {
          responsive: true,
          animation: {
            duration: 2000,
          },
          plugins: {
            title: {
              display: true,
              text: title,
              font: {
                size: 16
              }
            },
            legend: {
              position: 'top',
              labels: {
                font: {
                  size: 14
                },
                boxWidth: 20,
              },
            },
            tooltip: {
              callbacks: {
                label: function (context) {
                  const label = context.label || '';
                  const value = context.raw;
                  const total = context.chart._metasets[context.datasetIndex].total;
                  const percentage = ((value / total) * 100).toFixed(2);
                  return `${label}: ${value} (${percentage}%)`;
                }
              }
            },
            datalabels: {
              display: true,
              color: 'white',
              formatter: (value, context) => {
                const total = context.chart._metasets[context.datasetIndex].total;
                const percentage = ((value / total) * 100).toFixed(2);
                return `${percentage}%`;
              }
            }
          },
        },
      });
    };

    onMounted(() => {
      fetchData();
    });

    return {
      totalAtendimentos,
      atendimentosHoje,
      atendimentosEmAberto,
      atendimentosAgendados,
    };
  },
};
</script>

<style scoped>
.card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.icon-shape {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
}

.icon-shape i {
  color: #fff;
  top: 0;
}

canvas {
  max-height: 550px;
}
</style>