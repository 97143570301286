<template>
    <div class="position-absolute w-100 min-height-300 top-0"
        style="background-image: url('/img/bg.png'); background-position-y: 50%;">
        <span class="mask bg-primary opacity-6"></span>
    </div>
    <aside
        class="sidenav bg-white navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-4 "
        id="sidenav-main">
        <div class="sidenav-header">
            <i class="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none"
                aria-hidden="true" id="iconSidenav"></i>
            <a class="navbar-brand m-0" href="javascript:void(0);">
                <img src="/img/logo.png" class="navbar-brand-img h-100" alt="main_logo">
                <span class="ms-1 font-weight-bold">APP SUPORTE</span>
            </a>
        </div>
        <hr class="horizontal dark mt-0">
        <div class="collapse navbar-collapse w-auto custom-scrollbar" id="sidenav-collapse-main">
            <ul class="navbar-nav">
                <li class="nav-item">
                    <router-link class="nav-link" :class="{ 'active': $route.path === '/' }" to="/">
                        <div
                            class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                            <i class="ni ni-tv-2 text-warning text-sm opacity-10"></i>
                        </div>
                        <span class="nav-link-text ms-1">Dashboard</span>
                    </router-link>
                </li>
                <li class="nav-item mt-3">
                    <h6 class="ps-4 ms-2 text-uppercase text-xs font-weight-bolder opacity-6">Atendimentos</h6>
                </li>
                <li class="nav-item">
                    <router-link class="nav-link" :class="{ 'active': $route.path === '/chamados' }" to="/chamados">
                        <div
                            class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                            <i class="ni ni-laptop text-primary text-sm opacity-10"></i>
                        </div>
                        <span class="nav-link-text ms-1">Chamados</span>
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link class="nav-link" :class="{ 'active': $route.path === '/AbrirChamado' }"
                        to="/AbrirChamado">
                        <div
                            class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                            <i class="fa fa-plus text-primary text-sm opacity-10"></i>
                        </div>
                        <span class="nav-link-text ms-1">Abrir Chamado</span>
                    </router-link>
                </li>
                <li class="nav-item mt-3">
                    <h6 class="ps-4 ms-2 text-uppercase text-xs font-weight-bolder opacity-6">Ordens de Serviço</h6>
                </li>
                <li class="nav-item">
                    <router-link class="nav-link" :class="{ 'active': $route.path === '/OrdensServicoCriados' }"
                        to="/OrdensServicoCriados">
                        <div
                            class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                            <i class="fa fa-file-text text-primary text-sm opacity-10"></i>
                        </div>
                        <span class="nav-link-text ms-1">Ordens de Serviço</span>
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link class="nav-link" :class="{ 'active': $route.path === '/AbrirOS' }" to="/AbrirOS">
                        <div
                            class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                            <i class="fa fa-plus text-primary text-sm opacity-10"></i>
                        </div>
                        <span class="nav-link-text ms-1">Abrir uma O.S</span>
                    </router-link>
                </li>
                <li class="nav-item mt-3">
                    <h6 class="ps-4 ms-2 text-uppercase text-xs font-weight-bolder opacity-6">BOT WhatsAPP</h6>
                </li>
                <li class="nav-item">
                    <a class="nav-link" :class="{ 'active': $route.path === '/dashboard' }"
                        href="http://192.168.1.69:3000/dashboard/" target="_blank">
                        <div
                            class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                            <i class="fa fa-whatsapp text-primary text-sm opacity-10"></i>
                        </div>
                        <span class="nav-link-text ms-1">Conectar Bot</span>
                    </a>
                </li>
                <li class="nav-item">
                    <router-link class="nav-link" :class="{ 'active': $route.path === '/ListaBanidos' }"
                        to="/ListaBanidos">
                        <div
                            class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                            <i class="fa fa-times-circle text-primary text-sm opacity-10"></i>
                        </div>
                        <span class="nav-link-text ms-1">Lista de Exclusão</span>
                    </router-link>
                </li>
                <li class="nav-item mt-3">
                    <h6 class="ps-4 ms-2 text-uppercase text-xs font-weight-bolder opacity-6">Programas CBM</h6>
                </li>
                <li class="nav-item">
                    <router-link class="nav-link" :class="{ 'active': $route.path === '/UploadApp' }"
                        to="/UploadApp">
                        <div
                            class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                            <i class="fa fa-upload text-primary text-sm opacity-10"></i>
                        </div>
                        <span class="nav-link-text ms-1">Subir novo executavel</span>
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link class="nav-link" :class="{ 'active': $route.path === '/ListaExecutaveis' }"
                        to="/ListaExecutaveis">
                        <div
                            class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                            <i class="fa fa-window-restore text-primary text-sm opacity-10"></i>
                        </div>
                        <span class="nav-link-text ms-1">Lista de Executaveis</span>
                    </router-link>
                </li>
                <li class="nav-item mt-3">
                    <h6 class="ps-4 ms-2 text-uppercase text-xs font-weight-bolder opacity-6">Configurações</h6>
                </li>
                <li class="nav-item">
                    <router-link class="nav-link" :class="{ 'active': $route.path === '/clientes' }" to="/clientes">
                        <div
                            class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                            <i class="ni ni-single-02 text-primary text-sm opacity-10"></i>
                        </div>
                        <span class="nav-link-text ms-1">Clientes</span>
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link class="nav-link" :class="{ 'active': $route.path === '/produtos' }" to="/produtos">
                        <div
                            class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                            <i class="ni ni-basket text-primary text-sm opacity-10"></i>
                        </div>
                        <span class="nav-link-text ms-1">Produtos</span>
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link class="nav-link" :class="{ 'active': $route.path === '/categorias' }" to="/categorias">
                        <div
                            class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                            <i class="ni ni-bullet-list-67 text-primary text-sm opacity-10"></i>
                        </div>
                        <span class="nav-link-text ms-1">Categorias</span>
                    </router-link>
                </li>
                <li class="nav-item mt-3">
                    <h6 class="ps-4 ms-2 text-uppercase text-xs font-weight-bolder opacity-6">Sistema</h6>
                </li>
                <li class="nav-item">
                    <router-link class="nav-link" :class="{ 'active': $route.path === '/usuarios' }" to="/usuarios">
                        <div
                            class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                            <i class="ni ni-circle-08 text-primary text-sm opacity-10"></i>
                        </div>
                        <span class="nav-link-text ms-1">Usuários</span>
                    </router-link>
                </li>
                <li class="nav-item">
                    <a class="nav-link" @click="handleLogout">
                        <div
                            class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                            <i class="ni ni-user-run text-warning text-sm opacity-10"></i>
                        </div>
                        <span class="nav-link-text ms-1">Logout</span>
                    </a>
                </li>
            </ul>
        </div>
    </aside>
</template>

<script>
export default {
    methods: {
        handleLogout() {
            localStorage.removeItem('jwt');
            this.$router.push('/login');
        }
    }
}
</script>

<style scoped>
.nav-link.active {
    background-color: #f0f0f0;
    color: #007bff;
}

.custom-scrollbar {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
}

.custom-scrollbar::-webkit-scrollbar {
    width: 5px;
}

.custom-scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.custom-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;
}
</style>