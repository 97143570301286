<template>
    <div class="d-flex flex-column align-items-center justify-content-center vh-100 bg-light text-center">
        <div class="container">
            <h1 class="display-1 fw-bold text-primary">404</h1>
            <h2 class="mb-4">Página não encontrada</h2>
            <p class="lead mb-4">Oops! The page you are looking for does not exist. It might have been moved or deleted.</p>
            <router-link to="/" class="btn btn-primary btn-lg">
                <i class="fas fa-home me-2"></i>Go Home
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NotFound'
}
</script>

<style scoped>
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    background-color: #f8f9fa;
    color: #343a40;
}

.container {
    max-width: 600px;
}

h1 {
    font-size: 8rem;
}

h2 {
    font-size: 2rem;
    margin-bottom: 1.5rem;
}

p {
    font-size: 1.25rem;
}

.btn {
    font-size: 1.25rem;
    padding: 0.75rem 1.5rem;
}
</style>