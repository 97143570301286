<template>
  <div class="content-wrapper">
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6"></div>
        </div>
      </div>
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">
                  <i class="fas fa-list-alt mr-2 me-2"></i>Versões dos Executáveis
                </h3>
              </div>
              <div class="card-body">
                <div class="row mb-4">
                  <div class="col-md-4 form-group">
                    <label for="produto_id">
                      <i class="fas fa-box mr-1 me-1"></i>Produto
                    </label>
                    <select id="produto_id" class="form-control" v-model="selectedProductId" @change="fetchVersions">
                      <option disabled selected value="">Selecione um Produto</option>
                      <option v-for="produto in produtos" :key="produto.id" :value="produto.id">
                        {{ produto.nome }}
                      </option>
                    </select>
                  </div>
                </div>
                <div v-if="loading" class="text-center mt-4">
                  <i class="fas fa-spinner fa-spin fa-2x"></i>
                </div>
                <div v-else-if="sortedVersions.length > 0">
                  <table class="table table-bordered table-striped">
                    <thead>
                      <tr>
                        <th>Versão (Build)</th>
                        <th>Data de Upload</th>
                        <th>Backlog</th>
                        <th class="d-flex justify-content-center">Ações</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="version in sortedVersions" :key="version.id">
                        <td>{{ version.nome_versao }}</td>
                        <td>{{ formatDate(version.created_at) }}</td>
                        <td>{{ version.backlog }}</td>
                        <td>
                          <div class="d-flex justify-content-center">
                            <a :href="version.url_executavel" class="btn btn-sm btn-primary mr-2" download>
                              <i class="fas fa-download mr-1 text-white"></i> Download
                            </a>
                            <button @click="deleteVersion(version.id)" class="btn btn-sm btn-danger">
                              <i class="fas fa-trash mr-1 text-white"></i> Deletar
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div v-else-if="selectedProductId" class="alert alert-info mt-4">
                  <i class="fas fa-info-circle mr-2 text-white me-2"></i>Nenhuma versão encontrada para este produto.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import axios from '@/axios';
import Swal from 'sweetalert2';

const produtos = ref([]);
const selectedProductId = ref('');
const versions = ref([]);
const loading = ref(false);

onMounted(() => {
  fetchProdutos();
});

const fetchProdutos = async () => {
  try {
    const response = await axios.get('/produtos');
    produtos.value = response.data;
  } catch (error) {
    console.error('Erro ao buscar produtos:', error);
    Swal.fire('Erro', 'Não foi possível carregar a lista de produtos.', 'error');
  }
};

const fetchVersions = async () => {
  if (!selectedProductId.value) return;

  loading.value = true;
  versions.value = [];

  try {
    const response = await axios.get(`/produtos/${selectedProductId.value}/versoes`);
    versions.value = response.data;
  } catch (error) {
    console.error('Erro ao buscar versões:', error);
    Swal.fire('Erro', 'Não foi possível carregar as versões do produto.', 'error');
  } finally {
    loading.value = false;
  }
};

const deleteVersion = async (id) => {
  try {
    await Swal.fire({
      title: 'Tem certeza?',
      text: "Você não poderá reverter esta ação!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, delete!',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        await axios.delete(`/versao/${id}`);
        await fetchVersions();
        Swal.fire(
          'Deletado!',
          'A versão foi deletada com sucesso.',
          'success'
        );
      }
    });
  } catch (error) {
    console.error('Erro ao deletar versão:', error);
    Swal.fire('Erro', 'Não foi possível deletar a versão.', 'error');
  }
};

const sortedVersions = computed(() => {
  return [...versions.value].sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
});

const formatDate = (dateString) => {
  const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
  return new Date(dateString).toLocaleDateString('pt-BR', options);
};
</script>

<style scoped>
.alert-info {
    --bs-alert-color: #ffffff;
    --bs-alert-bg: #cff5fc;
    --bs-alert-border-color: #b8f0fa;
}

.content-wrapper {
  padding: 20px;
}

.card {
  margin-bottom: 20px;
}

.table {
  margin-top: 20px;
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}

.btn-danger {
  margin-left: 0.5rem;
}
</style>