<template>
    <div class="container-fluid py-4">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header pb-0">
                        <div class="d-flex align-items-center">
                            <p class="mb-0">Lista de Produtos</p>
                            <button class="btn btn-primary btn-sm ms-auto" @click="showAddProductModal">Adicionar
                                Produto</button>
                        </div>
                    </div>
                    <div style="min-height:60vh" class="card-body">
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Nome</th>
                                        <th scope="col">Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(product, index) in products" :key="product.id">
                                        <th scope="row">{{ index + 1 }}</th>
                                        <td>{{ product.nome }}</td>
                                        <td>
                                            <button class="btn btn-sm btn-warning m-lg-2"
                                                @click="showEditProductModal(product)">Editar</button>
                                            <button class="btn btn-sm btn-danger m-lg-2"
                                                @click="deleteProduct(product.id)">Excluir</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from '@/axios';
import Swal from 'sweetalert2';

export default {
    data() {
        return {
            products: []
        };
    },
    mounted() {
        this.fetchProducts();
    },
    methods: {
        async fetchProducts() {
            try {
                const response = await axios.get('/produtos');
                this.products = response.data;
            } catch (error) {
                console.error('Erro ao buscar produtos:', error);
            }
        },
        showAddProductModal() {
            Swal.fire({
                title: 'Adicionar Produto',
                input: 'text',
                inputLabel: 'Nome do Produto',
                inputPlaceholder: 'Digite o nome do produto',
                showCancelButton: true,
                confirmButtonText: 'Adicionar',
                cancelButtonText: 'Cancelar',
                preConfirm: (nome) => {
                    if (!nome) {
                        Swal.showValidationMessage('O nome do produto é obrigatório');
                    }
                    return nome;
                }
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        const response = await axios.post('/produtos', { nome: result.value });
                        this.products.push(response.data);
                        Swal.fire('Sucesso', 'Produto adicionado com sucesso!', 'success');
                    } catch (error) {
                        Swal.fire('Erro', 'Erro ao adicionar produto.', 'error');
                    }
                }
            });
        },
        showEditProductModal(product) {
            Swal.fire({
                title: 'Editar Produto',
                input: 'text',
                inputLabel: 'Nome do Produto',
                inputValue: product.nome,
                showCancelButton: true,
                confirmButtonText: 'Salvar',
                cancelButtonText: 'Cancelar',
                preConfirm: (nome) => {
                    if (!nome) {
                        Swal.showValidationMessage('O nome do produto é obrigatório');
                    }
                    return nome;
                }
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        const response = await axios.put(`/produtos/${product.id}`, { nome: result.value });
                        product.nome = response.data.nome;
                        Swal.fire('Sucesso', 'Produto editado com sucesso!', 'success');
                    } catch (error) {
                        Swal.fire('Erro', 'Erro ao editar produto.', 'error');
                    }
                }
            });
        },
        async deleteProduct(productId) {
            try {
                await axios.delete(`/produtos/${productId}`);
                this.products = this.products.filter(product => product.id !== productId);
                Swal.fire('Sucesso', 'Produto excluído com sucesso!', 'success');
            } catch (error) {
                Swal.fire('Erro', 'Erro ao excluir produto.', 'error');
            }
        }
    }
};
</script>

<style scoped>
.main-content {
    padding-top: 4rem;
}
</style>
