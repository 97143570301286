import { createApp } from 'vue';
import App from './App.vue';
import router from './router'; // Certifique-se de que o router está importado
import 'bootstrap';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import 'font-awesome/css/font-awesome.min.css'
import './registerServiceWorker'
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const app = createApp(App);

Chart.register(ChartDataLabels);

app.component('v-select', vSelect);


app.use(router);

// Adiciona a funcionalidade para desativar pull-to-refresh
app.mixin({
    mounted() {
        this.disablePullToRefresh();
    },
    methods: {
        disablePullToRefresh() {
            document.addEventListener('touchmove', this.preventPullToRefresh, { passive: false });
        },
        preventPullToRefresh(event) {
            // Checa se o evento é uma tentativa de pull-to-refresh
            if (event.cancelable && (event.touches[0].clientY <= 0)) {
                event.preventDefault();
            }
        }
    },
    beforeUnmount() {
        document.removeEventListener('touchmove', this.preventPullToRefresh);
    }
});

app.mount('#app');
