<template>
  <div class="content-wrapper">
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6"></div>
        </div>
      </div>
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">
                  <i class="fas fa-file-code mr-2 me-2"></i>Upload de novas versões dos Executáveis
                </h3>
              </div>
              <div class="card-body">
                <form @submit.prevent="handleSubmit">
                  <div class="row">
                    <div class="col-md-4 form-group">
                      <label for="produto_id">
                        <i class="fas fas-blue fa-box mr-1 me-1"></i>Produto*
                      </label>
                      <select id="produto_id" class="form-control" v-model="produto_id">
                        <option disabled selected value="">Selecione um Produto</option>
                        <option v-for="produto in produtos" :key="produto.id" :value="produto.id">
                          {{ produto.nome }}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-4 form-group">
                      <label for="build">
                        <i class="fas fas-blue fa-code-branch mr-1 me-1"></i>Versão (Build)*
                      </label>
                      <input type="text" id="build" class="form-control" v-model="build" placeholder="Digite a versão"
                        required />
                    </div>
                    <div class="col-md-4 form-group">
                      <label for="backlog">
                        <i class="fas fas-blue fa-solid fa-code mr-1 me-1"></i>Backlog*
                      </label>
                      <textarea id="backlog" class="form-control" v-model="backlog" placeholder="Descreva as mudanças" rows="1" required></textarea>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>
                      <i class="fas fas-blue fa-file-upload mr-1 me-1"></i>Arquivo Executável (.exe)*
                    </label>
                    <div class="dropzone" :class="{ 'dropzone-active': isDragging }"
                      @dragenter.prevent="isDragging = true" @dragleave.prevent="isDragging = false" @dragover.prevent
                      @drop.prevent="handleDrop" @click="$refs.fileInput.click()">
                      <input type="file" ref="fileInput" class="file-input" @change="handleFileChange" accept=".exe"
                        name="executavel" />
                      <div v-if="file">
                        <i class="fas fas-blue fa-file mr-2 me-1"></i>{{ file.name }}
                      </div>
                      <div v-else>
                        <i class="fas fas-blue fa-cloud-upload-alt fa-3x mb-3"></i>
                        <p>Arraste e solte o arquivo aqui ou clique para selecionar</p>
                      </div>
                    </div>
                  </div>
                  <button type="submit" class="btn btn-primary mt-4" :disabled="loading">
                    <i class="fas fas-blue fa-paper-plane text-white mr-2 me-1"></i>
                    <span v-if="loading">Carregando...</span>
                    <span v-else>Enviar Versão</span>
                  </button>
                  <div v-if="loading" class="text-center mt-2">
                    <i class="fas fas-blue fa-spinner fa-spin fa-2x"></i>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from '@/axios';
import Swal from 'sweetalert2';

const produtos = ref([]);
const produto_id = ref('');
const build = ref('');
const file = ref(null);
const backlog = ref('');
const isDragging = ref(false);
const loading = ref(false);

onMounted(() => {
  fetchProdutos();
});

const fetchProdutos = async () => {
  try {
    const response = await axios.get('/produtos');
    produtos.value = response.data;
  } catch (error) {
    console.error('Erro ao buscar produtos:', error);
  }
};

const handleFileChange = (event) => {
  const files = event.target.files || event.dataTransfer.files;
  if (files.length > 0) {
    file.value = files[0];
  }
};

const handleDrop = (event) => {
  isDragging.value = false;
  handleFileChange(event);
};

const handleSubmit = async () => {
  if (!produto_id.value || !build.value || !backlog.value || !file.value) {
    Swal.fire('Erro', 'Por favor, preencha todos os campos e selecione um arquivo.', 'error');
    return;
  }

  const formData = new FormData();
  formData.append('nome_versao', build.value);
  formData.append('file', file.value);
  formData.append('backlog', backlog.value);

  loading.value = true;

  try {
    await axios.post(`/produtos/${produto_id.value}/versao`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'accept': 'application/json',
      },
    });

    Swal.fire('Sucesso', 'Versão enviada com sucesso!', 'success').then(() => {
      location.reload();
    });

    produto_id.value = '';
    build.value = '';
    file.value = null;
    backlog.value ='';
  } catch (error) {
    console.error('Erro ao enviar a versão:', error);
    const errorMessage = error.response?.data?.message || 'Ocorreu um erro ao enviar a versão.';
    Swal.fire('Erro', errorMessage, 'error');
  } finally {
    loading.value = false;
  }
};
</script>

<style scoped>
.content-wrapper {
  padding: 20px;
}

.card {
  margin-bottom: 20px;
}

.dropzone {
  border: 2px dashed #ccc;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.dropzone-active {
  border-color: #007bff;
  background-color: #f8f9fa;
}

.file-input {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.dropzone p {
  margin-top: 10px;
  color: #6c757d;
}

.fas-blue {
  color: #007bff;
}
</style>