<template>
    <div class="container-fluid py-4">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header pb-0">
                        <div class="d-flex align-items-center">
                            <p class="mb-0">Usuários</p>
                            <button class="btn btn-primary btn-sm ms-auto" @click="showAddUsuario">Adicionar
                                Usuário</button>
                        </div>
                    </div>
                    <div style="min-height:60vh" class="card-body">
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Nome</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(usuario, index) in usuarios" :key="usuario.id">
                                        <th scope="row">{{ index + 1 }}</th>
                                        <td>{{ usuario.name }}</td>
                                        <td>{{ usuario.email }}</td>
                                        <td>
                                            <button class="btn btn-sm btn-warning m-lg-2"
                                                @click="showEditUsuarioModal(usuario)">Editar</button>
                                            <button class="btn btn-sm btn-danger m-lg-2"
                                                @click="deleteUsuario(usuario.id)">Excluir</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import axios from '@/axios';
import Swal from 'sweetalert2';

const usuarios = ref([]);

const fetchUsuarios = async () => {
    try {
        const response = await axios.get('/users');
        usuarios.value = response.data.users;
    } catch (error) {
        console.error('Erro ao buscar usuários:', error);
    }
}

onMounted(async () => {
    await fetchUsuarios();
});

const showAddUsuario = async () => {
    Swal.fire({
        title: 'Adicionar Usuário',
        html: `
            <input id="name" class="swal2-input" placeholder="Nome">
            <input id="email" class="swal2-input" placeholder="Email">
            <input id="password" type="password" class="swal2-input" placeholder="Senha">
        `,
        focusConfirm: false,
        preConfirm: () => {
            const name = Swal.getPopup().querySelector('#name').value;
            const email = Swal.getPopup().querySelector('#email').value;
            const password = Swal.getPopup().querySelector('#password').value;
            if (!name || !email || !password) {
                Swal.showValidationMessage('Todos os campos são obrigatórios');
            }
            return { name, email, password };
        }
    }).then(async (result) => {
        if (result.isConfirmed) {
            try {
                const response = await axios.post('/register', result.value);
                usuarios.value.push({
                    id: response.data.user.id,
                    name: result.value.name,
                    email: result.value.email
                });
                Swal.fire('Sucesso', 'Usuário adicionado com sucesso!', 'success');
            } catch (error) {
                Swal.fire('Erro', 'Erro ao adicionar usuário.', 'error');
                console.log(error);
            }
        }
    });
};

const deleteUsuario = async (usuarioId) => {
    try {
        await axios.delete(`/users/${usuarioId}`);
        usuarios.value = usuarios.value.filter(usuario => usuario.id !== usuarioId);
        Swal.fire('Sucesso', 'Usuário excluído com sucesso!', 'success');
    } catch (error) {
        Swal.fire('Erro', 'Erro ao excluir usuário.', 'error');
    }
}

const showEditUsuarioModal = async (usuario) => {
    Swal.fire({
        title: 'Editar Usuário',
        html: `
            <input id="name" class="swal2-input" value="${usuario.name}" placeholder="Nome">
            <input id="email" class="swal2-input" value="${usuario.email}" placeholder="Email">
        `,
        focusConfirm: false,
        preConfirm: () => {
            const name = Swal.getPopup().querySelector('#name').value;
            const email = Swal.getPopup().querySelector('#email').value;
            if (!name || !email) {
                Swal.showValidationMessage('Todos os campos são obrigatórios');
            }
            return { name, email };
        }
    }).then(async (result) => {
        if (result.isConfirmed) {
            try {
                await axios.put(`/users/${usuario.id}`, result.value);
                usuario.name = result.value.name;
                usuario.email = result.value.email;
                Swal.fire('Sucesso', 'Usuário editado com sucesso!', 'success');
            } catch (error) {
                Swal.fire('Erro', 'Erro ao editar usuário.', 'error');
            }
        }
    });
}
</script>

<style scoped>
.main-content {
    padding-top: 4rem;
}
</style>
