<template>
  <div id="app">
    <!-- Sidebar é exibido quando showSidebar é true -->
    <SideBar v-if="showSidebar" />
    <div class="main-content position-relative max-height-vh-100 h-100">
      <!-- Header e Footer são exibidos quando showSidebar é true -->
      <HeaderPage v-if="showSidebar" />
      <router-view />
    </div>
    <FooterTemplate v-if="showSidebar" />
  </div>
</template>

<script>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import HeaderPage from './components/HeaderPage.vue';
import FooterTemplate from './components/FooterTemplate.vue';
import SideBar from './components/SideBar.vue';

export default {
  name: 'App',
  components: {
    HeaderPage,
    FooterTemplate,
    SideBar
  },
  setup() {
    const route = useRoute();

    // Ajuste para a lógica de exibição do Sidebar
    const showSidebar = computed(() => {
      // Lista de rotas onde o Sidebar não deve ser exibido
      const noSidebarRoutes = ['/login', '/404'];
      // Verifica se a rota atual está na lista de exceções ou começa com '/print'
      if (noSidebarRoutes.includes(route.path) || route.path.startsWith('/Print')) {
        return false;
      }
      // Caso padrão: o Sidebar é exibido
      return true;
    });

    return {
      showSidebar
    };
  }
}
</script>

<style>
/* Estilos globais para App.vue */
</style>